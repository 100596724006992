:root {
    --primary-color: #148CEC;
    --secondary-color: #E7F3FD;
    --light-color: #fff;
    --dark-color: #041C2F;
    --text-color: #041C2F;
    --input-text-color: #6B7280;
    --input-border-color: #9CA3AF;
    --input-bg-color: #F9FAFB;
    --footer-bg-color: #0B548E;
}